/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react';
import type { FormEvent } from 'react';
import { useNavigate } from 'react-router';
import { Spin, Button, Input, message } from 'antd';
import { useAuth } from '@unbooking/ui-auth';
import './styles.scss';

const SignInPage: FC = () => {
  const { authorize } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    const email = (e.target as any).children[0].value;
    if (!email) return;
    setIsLoading(true);

    try {
      await authorize(email);
    } catch (err: any) {
      message.error(err.message);
    }

    setIsLoading(false);
  }

  return (
    <section className="login-container">
      <div className="login-panel">
        <div className="row">
          <span className="login-greeting">
            Welcome to the <br />
            <strong>Freight Management</strong>
          </span>
        </div>

        <form onSubmit={onSubmit} className="flex flex-col w-full">
          <Input name="email" type="email" />
          {isLoading ? (
            <Spin className="login-loading" size="large" spinning={isLoading} />
          ) : (
            <Button className="login-link" type="link" htmlType="submit">
              Sign in
            </Button>
          )}
        </form>
      </div>
    </section>
  );
};

export default SignInPage;
