/* eslint-disable import/no-relative-packages */
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '@unbooking/ui-auth';
import { authConfig } from '@common/utils';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@assets/styles/index.scss';
import '../node_modules/@unbooking/ui-kit/style.css';

const axiosInstance = axios.create();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Note: Disable StrictMode according to https://github.com/teslamotors/informed/issues/315
root.render(
  <AuthProvider authConfig={authConfig} axiosInstance={axiosInstance}>
    <Router>
      <App />
    </Router>
  </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
