import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useParamsParse() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const facility = query.get('facility_id');
  const country = query.get('facility_country') || '';

  useEffect(() => {
    if (facility) {
      localStorage.setItem('facility', facility);
      localStorage.setItem('country', country);
    }
  }, [facility, country]);

  return {
    isParsed: !!facility,
  };
}
