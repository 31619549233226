import {
  GefsLocationsParams,
  IAddContractData,
  IContractsListParams,
  IDestination,
  IGoodCategoriesListParams,
  ILetterDeleteParams,
  ILetterPostData,
  ILetterProviderParams,
  IListParams,
  ILoadingUnitsListParams,
  IPostNewAssignment,
  IProvidersListParams,
  IQuotationPayload,
  IRecipient,
  IRequestLettersListParams,
  IRequestListParams,
  ISendProvidersQuotationPayload,
  ISignLetterParams,
  IVendorAnalysisParams,
  IVendorAnalysisQuotationListParams,
  IVendorDetailsPatch,
  IWvUnitsListParams,
  PostedLineItem,
  ReportsDataParams,
} from '@common/interfaces';
import IFreightRepository from '@common/interfaces/FreightRepository';
import { IDetailsUpdate, IRequestCancelPatch } from '@common/interfaces/Common';
import { IMultipleNotificationPayload, IMultipleQuotationPayload } from '@pages/BatchDetailsPage';
import convertSwitches from '@common/utils/convertSwitches';
import axiosInstance from '@common/utils/axios';
import type { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';

class FreightRepository extends AxiosRepository implements IFreightRepository {
  public constructor(protected authClient: AxiosInstance) {
    super((axiosInstance as any).create(), authClient);
  }

  async getSwitches() {
    const { data } = await this.get(`common/waffle/switches/`);
    return convertSwitches(data);
  }

  async getGefsLocations(params: GefsLocationsParams) {
    const { data } = await this.get(`core/gefs/locations`, { params });
    return data;
  }

  // Facility Bookings

  async getDOData(facilityId: string) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/digital-office/`);
    return data;
  }

  async getReportsData(facilityId: string, params: ReportsDataParams) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/reports/`, { params });
    return data;
  }

  async getGoodCategoriesList(facilityId: number, params?: IGoodCategoriesListParams) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/good-categories/`, {
      params,
    });
    return data;
  }

  async getLoadingUnitsList(facilityId: number, params?: ILoadingUnitsListParams) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/loading-units/`, {
      params,
    });
    return data;
  }

  async getWvUnitsList(facilityId: number, params?: IWvUnitsListParams) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/weight-volume-units/`, {
      params,
    });
    return data;
  }

  async getRequestsList(facilityId: number, params: IRequestListParams) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/`, {
      params,
    });
    return data;
  }

  async getBatchesList(facilityId: number, params: IRequestListParams) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/batches/`, {
      params,
    });
    return data;
  }

  async getBatchDetail(facilityId: number, uuid: string, params: IListParams) {
    const { data } = await this.get(
      `facilities/${facilityId}/bookings/batches/${uuid}/booking-list/`,
      {
        params,
      },
    );
    return data;
  }

  async getAllAssignmentsReportFile(facilityId: number, params: IListParams) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/assignments/export/all/`, {
      responseType: 'arraybuffer',
      params,
    });

    return data;
  }

  async getSelectedAssignmentsReportFile(facilityId: number, params: IListParams) {
    const { data } = await this.get(
      `facilities/${facilityId}/bookings/assignments/export/selected/`,
      {
        responseType: 'arraybuffer',
        params,
      },
    );

    return data;
  }

  async getAllBatchesReportFile(facilityId: number, batchId: string, params: IListParams) {
    const { data } = await this.get(
      `facilities/${facilityId}/bookings/batches/${batchId}/export/all/`,
      {
        responseType: 'arraybuffer',
        params,
      },
    );

    return data;
  }

  async getSelectedBatchesReportFile(facilityId: number, batchId: string, params: IListParams) {
    const { data } = await this.get(
      `facilities/${facilityId}/bookings/batches/${batchId}/export/selected/`,
      {
        responseType: 'arraybuffer',
        params,
      },
    );

    return data;
  }

  async getAssignmentsList(facilityId: number, params: IRequestListParams) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/assignments`, {
      params,
    });
    return data;
  }

  async getRequestDetails(facilityId: number, freightId: string) {
    const { data } = await this.get(`facilities/${facilityId}/bookings/${freightId}/`);
    return data;
  }

  async getProvidersQuotation(facilityId: number, booking_uuid: string) {
    const { data } = await this.get(
      `facilities/${facilityId}/bookings/${booking_uuid}/providers-quotations/`,
    );
    return data;
  }

  async getAmendAssignments(facilityId: number, booking_uuid: string, params: IRequestListParams) {
    const { data } = await this.get(
      `facilities/${facilityId}/bookings/${booking_uuid}/amend_assignments/`,
      { params },
    );
    return data;
  }

  async updateRequestDetails(facilityId: number, freightId: string, payload: IDetailsUpdate) {
    const { data } = await this.patch(`facilities/${facilityId}/bookings/${freightId}/`, {
      ...payload,
    });
    return data;
  }

  async cancelRequest(facilityId: number, freightId: string, payload: IRequestCancelPatch) {
    const { data } = await this.patch(
      `facilities/${facilityId}/bookings/${freightId}/cancellation/`,
      {
        ...payload,
      },
    );
    return data;
  }

  async updateDestination(
    facilityId: number,
    destinationUuid: string,
    payload: Partial<IDestination>,
  ) {
    const { data } = await this.patch(
      `facilities/${facilityId}/bookings/destinations/${destinationUuid}/`,
      {
        ...payload,
      },
    );
    return data;
  }

  async updateRecipient(facilityId: number, recipientUuid: string, payload: Partial<IRecipient>) {
    const { data } = await this.patch(
      `facilities/${facilityId}/bookings/sender-recipient/${recipientUuid}/`,
      {
        ...payload,
      },
    );
    return data;
  }

  async sendQuotationRequest(facilityId: number, freightId: string, payload: IQuotationPayload) {
    const { data } = await this.post(
      `facilities/${facilityId}/bookings/${freightId}/quotation-requests/`,
      {
        ...payload,
      },
    );
    return data;
  }

  async sendMultipleQuotation(
    facilityId: number,
    batch_id: string,
    payload: IMultipleQuotationPayload,
  ) {
    const { data } = await this.post(
      `facilities/${facilityId}/bookings/batches/${batch_id}/multiple-quotation-requests/`,
      {
        ...payload,
      },
    );
    return data;
  }

  async sendMultipleNotification(
    facilityId: number,
    batch_id: string,
    payload: IMultipleNotificationPayload,
  ) {
    const { data } = await this.post(
      `facilities/${facilityId}/bookings/batches/${batch_id}/send-multiple-assignments/`,
      {
        ...payload,
      },
    );
    return data;
  }

  async sendProvidersQuotation(
    facilityId: number,
    booking_id: string,
    payload: ISendProvidersQuotationPayload,
  ) {
    const { data } = await this.post(
      `facilities/${facilityId}/bookings/${booking_id}/providers-quotations/`,
      payload,
    );
    return data;
  }

  async sendLineItemRequest(facilityId: number, freightId: string, payload: PostedLineItem) {
    const { data } = await this.post(`facilities/${facilityId}/bookings/${freightId}/line-items/`, {
      ...payload,
    });
    return data;
  }

  async deleteLineItems(facilityId: number, lineItemUuid: string) {
    await this.delete(`facilities/${facilityId}/bookings/line-items/${lineItemUuid}/`);
  }

  async postNewAssignment(facilityId: number, bookingId: string, payload: IPostNewAssignment) {
    const { data } = await this.post(
      `facilities/${facilityId}/bookings/${bookingId}/amend_assignments/`,
      {
        ...payload,
      },
    );
    return data;
  }

  // Letters
  async getLettersList(facilityId: number, params: IRequestLettersListParams) {
    const { data } = await this.get(`/providers/letters/facilities/${facilityId}/`, {
      params,
    });
    return data;
  }

  async getPendingLettersList(facilityId: number, params: IRequestLettersListParams) {
    const { data } = await this.get(
      `/providers/letters/facilities/${facilityId}/waiting_for_my_signature`,
      {
        params,
      },
    );
    return data;
  }

  async postLetterData(
    providerId: string,
    payload: ILetterPostData,
    params: ILetterProviderParams,
  ) {
    const { data } = await this.post(
      `/providers/letters/providers/${providerId}/`,
      {
        ...payload,
      },
      { params },
    );
    return data;
  }

  async putLetterData(uuid: string) {
    const { data } = await this.put(`/providers/letters/${uuid}/`);
    return data;
  }

  async deleteLetterData(uuid: string, params: ILetterDeleteParams) {
    const { data } = await this.delete(`/providers/letters/${uuid}/`, { data: params });
    return data;
  }

  async getOptionsForLetterCreation(facilityId: number, letter_type: string) {
    const { data } = await this.get(
      `/providers/letters/facilities/${facilityId}/options-for-create/`,
      { params: { letter_type } },
    );
    return data;
  }

  async getLetterProviderData(providerId: string, params: ILetterProviderParams) {
    const { data } = await this.get(`/providers/letters/providers/${providerId}/`, {
      params,
    });
    return data;
  }

  async getLetter(letterId: string) {
    const { data } = await this.get(`/providers/letters/${letterId}`);
    return data;
  }

  async signLetter(letterId: string, params: ISignLetterParams) {
    const { data } = await this.post(
      `/providers/letters/${letterId}/generate_signing_link/`,
      params,
    );
    return data;
  }

  async refreshLetterStatus(letterId: string) {
    const { data } = await this.post(`/providers/letters/${letterId}/docusign_refresh/`);
    return data;
  }

  // Provider Contracts

  async getContractsList(params: IContractsListParams) {
    const { data } = await this.get(`providers/transport-contracts/`, {
      params,
    });
    return data;
  }

  async addContract(payload: IAddContractData, contractId: string) {
    const { data } = await this.post(`providers/transport-contracts/${contractId}/add-provider/`, {
      ...payload,
    });
    return data;
  }

  // Vendors Management

  async getProvidersList(params: IProvidersListParams) {
    const { data } = await this.get(`providers/`, {
      params,
    });
    return data;
  }

  async getVendorsList(facilityId: number, params: IRequestListParams) {
    const { data } = await this.get(`providers/${facilityId}/providers/`, {
      params,
    });
    return data;
  }

  async getVendorDetails(facilityId: string, vendorId: string) {
    const { data } = await this.get(
      `providers/${facilityId}/providers/${vendorId}/detail-information/`,
    );
    return data;
  }

  async patchVendorDetails(facilityId: string, vendorId: string, payload: IVendorDetailsPatch) {
    const { data } = await this.patch(
      `providers/${facilityId}/providers/${vendorId}/detail-information/`,
      {
        ...payload,
      },
    );
    return data;
  }

  async getVendorAnalysis(facilityId: string, vendorId: string, params: IVendorAnalysisParams) {
    const { data } = await this.get(
      `providers/${facilityId}/providers/${vendorId}/quotations-analysis/`,
      { params },
    );
    return data;
  }

  async getVendorQuotations(
    facilityId: string,
    vendorId: string,
    params: IVendorAnalysisQuotationListParams,
  ) {
    const { data } = await this.get(
      `providers/${facilityId}/providers/${vendorId}/line_item_quotations/`,
      {
        params,
      },
    );
    return data;
  }

  async getVendorLetters(facilityId: string, vendorId: string, params: IRequestListParams) {
    const { data } = await this.get(`providers/${facilityId}/providers/${vendorId}/letters/`, {
      params,
    });
    return data;
  }

  async getVendorQuotationsReportFile(facilityId: string, vendorId: string, params: IListParams) {
    const { data } = await this.get(
      `providers/${facilityId}/providers/${vendorId}/line_item_quotations/export/`,
      {
        responseType: 'arraybuffer',
        params,
      },
    );

    return data;
  }
}

export default FreightRepository;
